<template>
	<v-row>
		<v-img src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/lighthouse_leads_desktop_Qgopmx.png">
			</v-img>
			<v-row wrap class="mt-6">
		<v-col cols="12" md="4" class="text-left">
			<p>{{ description }}</p>
		</v-col>
		<v-col cols="12" md="4" v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])">
			<q-agent-lookup
				:filters="{ Status: 'Active,Available,Stalled', attr: 'StateLicenses' }"
				label="Agent"
				v-model="AgentCode"
				placeholder="Type to Search for Agent"
			/>
		</v-col>
		<v-col v-if="hasRole(['Staff', 'SuperAdmin', 'Exec'])" cols="12" md="4">
			<v-btn
				:disabled="disabled_button"
				color="primary"
				large
				@click="startNewOrder()"
			>
				Create New Order
				<v-icon right>fas fa-cart-plus</v-icon>
			</v-btn>
		</v-col>
		<v-col v-else-if="user.Agent.Status == 'Active'" cols="12" md="4">
			<v-btn
				:disabled="hasLeadOrderPrivilegeRevoked"
				color="primary"
				large
				@click="startNewOrder()"
			>
				Create New Order
				<v-icon right>fas fa-cart-plus</v-icon>
			</v-btn>
		</v-col>
		<v-col v-else cols="12">
			<v-alert color="orange">
				Sorry. You cannot create new lead order until your account is
				"Active". Please contact your Agency Owner to re-activate your
				account.
			</v-alert>
		</v-col>
		<v-col cols="12">
			<q-digital-lead-order-data-table
				:lead-types="['Lighthouse']"
				v-on:editorder="editOrder"
				v-on:reopenorder="reopenOrder"
				ref="lead_order_datatable"
				:agent-code="AgentCode"
				title="Lighthouse Orders"
				key="quility_digital_leadorder_data"
			/>
		</v-col>
		<v-dialog
			ref="new_digital_order_dialog"
			v-model="showEditLeadOrder"
			:width="850"
		>
			<v-card class="text-center">
				<v-img src="https://hq-image-cdn.azureedge.net/servers/production/cms_images/lighthouse-leads-mobile_zNxXIO.png">
				</v-img>
				<q-edit-digital-lead-order-form
					title="Lighthouse Order Form"
					:description="description"
					key="MyDigitalLeadOrders"
					ref="lead_order_form"
					:agent-code="agent_code"
					:id="current_order_id"
					v-on:order_saved="orderSaved"
					v-on:cancel="showEditLeadOrder = false"
					lead-type="Lighthouse"
					lead-level="DLHA"
				/>
			</v-card>
		</v-dialog>
		</v-row>
	</v-row>
</template>
<script>
import QAgentLookup from "@/components/utils/QAgentLookup.vue";
import QEditDigitalLeadOrderForm from "@/components/lead_orders/QEditDigitalLeadOrderForm.vue";
import QDigitalLeadOrderDataTable from "@/components/datatables/LeadOrders/QDigitalLeadOrderDataTable.vue";

export default {
	components: {
		QAgentLookup,
		QEditDigitalLeadOrderForm,
		QDigitalLeadOrderDataTable,
	},
	data() {
		return {
			showEditLeadOrder: false,
			AgentCode: null,
			order_AgentCode: null,
			current_order_id: "new",
			description: 'Lighthouse leads are Digitally Generated Leads. Standing orders are accepted and processed in HQ. State lines can be over filled to meet the total amount requested for this order.'
		};
	},
	methods: {
		orderSaved: function () {
			this.showEditLeadOrder = false;
			this.$refs.lead_order_datatable.refresh();
			this.$refs.lead_order_form.reset();
		},
		showEditOrderForm(order) {
			this.showEditLeadOrder = true;
			this.$nextTick(() => {
				this.$refs.lead_order_form.order = order;
				this.$refs.lead_order_form.reset();
			});
		},
		editOrder(item) {
			this.order_AgentCode = item.AgentCode;
			this.AgentCode = null;
			this.current_order_id = item.ID;
			var order = {
				...item,
				LeadOrderLines: [],
			};
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				order.OrderStatus = item.OrderStatus;
			}
			item.lead_order_lines.forEach(function (line) {
				if (
					line.OrderLineStatus == "Open" ||
					line.OrderLineStatus == "Needs Approval" ||
					line.OrderLineStatus == "Paid Overflow" ||
					line.OrderLineStatus == "Unpaid Overflow"
				) {
					var l = {
                        "ID": line.ID,
                        "LeadType": line.LeadType,
                        "State": line.State,
                        "Qty": line.QtyRequested,
                        "ProductType": line.ProductType,
                        "County": line.County,
                        "OrderLineStatus": line.OrderLineStatus
                    }
                    order.LeadOrderLines.push(l);
				}
			});
			this.showEditOrderForm(order);
		},
		reopenOrder(item) {
			this.order_AgentCode = item.AgentCode;
			this.AgentCode = null;
			this.current_order_id = item.ID;
			const order = {
				...item,
				LeadOrderLines: [],
			};
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				order.OrderStatus = item.OrderStatus;
			}
			item.lead_order_lines.forEach(function (line) {
				if (
					line.OrderLineStatus == "Complete" &&
					item.OrderStatus == "Complete"
				) {
					order.LeadOrderLines.push({
						...line,
						OrderLineStatus: "Open",
					});
				}
				if (
					line.OrderLineStatus == "Canceled" &&
					item.OrderStatus == "Canceled"
				) {
					order.LeadOrderLines.push({
						...line,
						OrderLineStatus: "Open",
					});
				}
			});
			this.showEditOrderForm(order);
		},
		startNewOrder() {
			this.current_order_id = "new";
			this.showEditLeadOrder = true;
			this.order_AgentCode = null;
			this.$nextTick(() => {
				this.$refs.lead_order_form.reset();
			});
		},
	},
	computed: {
		hasLeadOrderPrivilegeRevoked() {
			return this.getConfig('LeadOrderPrivilegeRevoked')
		},
		disabled_button() {
			return Boolean(this.hasRole(["Staff", "SuperAdmin", "Exec"]) && this.AgentCode == null) || this.hasLeadOrderPrivilegeRevoked
		},
		agent_code() {
			if (this.hasRole(["Staff", "SuperAdmin", "Exec"])) {
				return this.order_AgentCode
					? this.order_AgentCode
					: this.AgentCode;
			}
			return this.user.Agent.AgentCode;
		},
	},
};
</script>
